import React from "react"
import { graphql } from "gatsby"
import {
  Box,
  Heading,
  Text,
  Stack,
  useColorModeValue as mode,
} from "@chakra-ui/react"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import { formatDate } from "../utils/date-helpers"
import ContentfulText from "../components/contentful/ContentfulText"
import ContentfulMedia from "../components/contentful/ContentfulMedia"
import { CTA } from "../components/landingpage/CTA"

const BlogPost = ({ data }) => {
  const pageData = data.contentfulBlogPost

  console.log(pageData.components)

  return (
    <Layout>
      <Meta title={pageData.title} description={pageData.heroDescription} />
      <Box as="section" bg={mode("gray.50", "gray.800")} pt="16" pb="24">
        <Box
          maxW={{ base: "xl", md: "7xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          <Stack
            direction={{ base: "column", lg: "row" }}
            spacing={{ base: "3rem", lg: "2rem" }}
            mt="8"
            align={{ lg: "center" }}
            justify="space-between"
          >
            <Box flex="1" maxW={{ lg: "520px" }}>
              <Text
                size="xs"
                textTransform="uppercase"
                fontWeight="semibold"
                color={mode("green.600", "green.400")}
                letterSpacing="wide"
              >
                {formatDate(pageData.postDate)}
              </Text>
              <Heading
                as="h1"
                size="3xl"
                color={mode("bullseyeOrange.500", "bullseyeOrange.300")}
                mt="8"
                fontWeight="extrabold"
                letterSpacing="tight"
              >
                {pageData.title}
              </Heading>
              <Text
                color={mode("gray.600", "gray.400")}
                mt="4"
                fontSize="lg"
                fontWeight="medium"
              >
                {pageData.blogSummary.blogSummary}
              </Text>
            </Box>
            <Box
              pos="relative"
              w={{ base: "full", lg: "560px" }}
              h={{ base: "auto", lg: "560px" }}
            >
              <Box
                pos="absolute"
                w="100%"
                h="100%"
                top="-4"
                left="-4"
                bg={mode("gray.200", "gray.700")}
              />
              <GatsbyImage
                image={pageData.blogImage.gatsbyImage}
                alt={pageData.blogImage.description}
              />
            </Box>
          </Stack>
        </Box>
        <Box
          maxW={"800px"}
          mt={12}
          pt={0}
          mx={"auto"}
          bg={mode("white", "gray.700")}
        >
          <Box px={8} pt={8}>
            <div
              id="audiofeed-embed"
              dangerouslySetInnerHTML={{
                __html: `
            <script src="https://audiofeed.ai/api/feeds/195b98ba-197c-49c7-bfdd-fe8ab3c4cea3/embed?key=8724093b-9a10-428f-9595-10fb785fbe71" type="text/javascript"></script>
            `,
              }}
            />
          </Box>
          {pageData.components.map(component => {
            if (component?.__typename === "ContentfulTextComponent") {
              return (
                <ContentfulText
                  key={component.id}
                  id={component.id}
                  heading={component.heading}
                  headingColor={component.headingColor}
                  text={component.text}
                  sectionTextAlign={"left"}
                  pt={0}
                  extraMargin={0}
                />
              )
            } else if (component?.__typename === "ContentfulMediaComponent") {
              console.log("media", component.media)
              return (
                <ContentfulMedia
                  key={component.id}
                  id={component.id}
                  gatsbyImage={component.media.gatsbyImage}
                  description={component.media.description}
                  pt={8}
                  pb={8}
                />
              )
            }
          })}
        </Box>
      </Box>
      <CTA />
    </Layout>
  )
}

export const data = graphql`
  query($ContenfulId: String!) {
    contentfulBlogPost(id: { eq: $ContenfulId }) {
      title
      blogSummary {
        blogSummary
      }
      title
      postDate
      blogImage {
        description
        gatsbyImage(width: 1024)
      }
      components {
        ...ContentfulTextFragment
        ...ContentfulMediaFragment
      }
    }
  }
`

export default BlogPost
