export function formatDate(dateString) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const date = new Date(dateString)
  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  const ordinal = day => {
    if (day % 10 === 1 && day !== 11) {
      return day + "st"
    }
    if (day % 10 === 2 && day !== 12) {
      return day + "nd"
    }
    if (day % 10 === 3 && day !== 13) {
      return day + "rd"
    }
    return day + "th"
  }

  return `${months[monthIndex]} ${ordinal(day)}, ${year}`
}
