import {
  Box,
  Button,
  Text,
  Stack,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react"
import * as React from "react"
import { Link as GatsbyLink } from "gatsby"

export const CTA = () => {
  return (
    <Box as="section" bg={useColorModeValue("gray.200", "gray.700")}>
      <Box
        maxW="2xl"
        mx="auto"
        px={{ base: "6", lg: "8" }}
        py={{ base: "16", sm: "20" }}
        textAlign="center"
      >
        <Heading
          as="h2"
          size="2xl"
          fontWeight="extrabold"
          letterSpacing="tight"
        >
          Ready to make your neighbors jealous?
        </Heading>
        <Text mt="4" fontSize="lg">
          If you want to make your lawn the talk of the neighborhood, give us a
          call or request a free quote!
        </Text>
        <Text
          mt="4"
          fontSize="2xl"
          color={useColorModeValue("orange.500", "orange.400")}
        >
          (605) 204-5458
        </Text>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing="4"
          mt="8"
          align="center"
          justify="center"
        >
          <Button
            size="lg"
            minW="210px"
            colorScheme="bullseyeOrange"
            height="14"
            px="8"
            as={GatsbyLink}
            to="/request-quote"
          >
            Get a Free Quote
          </Button>
          <Button
            size="lg"
            bg="white"
            color="gray.800"
            _hover={{ bg: "gray.50" }}
            height="14"
            px="8"
            shadow="base"
            fontSize="md"
            minW="210px"
            as={GatsbyLink}
            to="/company/contact"
          >
            Contact Us
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
