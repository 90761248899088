import React from "react"
import { Box, Container, useColorModeValue } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const ContentfulMedia = ({ id, gatsbyImage, description, ...rest }) => (
  <Box pt={50} width={`100%`} {...rest}>
    <Container mt="0" minHeight={0} maxW={"800px"}>
      <Box textAlign="center" mb="0">
        <GatsbyImage image={gatsbyImage} alt={description} />
      </Box>
    </Container>
  </Box>
)

export default ContentfulMedia

export const query = graphql`
  fragment ContentfulMediaFragment on ContentfulMediaComponent {
    __typename
    id
    media {
      description
      gatsbyImage(width: 1024)
      title
    }
  }
`
